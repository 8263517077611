body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

svg.recharts-surface {
  width: 100% !important;
}

.pie svg.recharts-surface {
  width: 200px;
  height: 200px;
}

.circular > div[data-test-id="CircularProgressbarWithChildren"] > div {
  width: 24rem !important;
  height: 24rem !important;
  margin: 0 auto;
}

.absolute{
  position: absolute;
}
.pl-20{
  padding-left: 20px
}
.pointer{
  cursor: pointer;
}

/* QR code background */
.QRcode-bg {
  position: relative;
  border: 3px solid #E5B910;
  border-radius: 12px;
  background: #fff;
  transition: all 0.05s linear;
}

.QRcode-bg:before,
.QRcode-bg:after {
  content: "";
  position: absolute;
  background: #fff;
  transition: all 0.2s linear;
}

.QRcode-bg:before {
  width: calc(100% + 9px);
  height: calc(100% - 64px);
  top: 32px;
  left: -4px;
}

.QRcode-bg:after {
  height: calc(100% + 9px);
  width: calc(100% - 64px);
  top: -3px;
  left: 32px;
}

.QRcode-bg img {
  height: 120px;
  padding: 10px;

}

.mt-40{
    margin-top: 40px;
}
.mt-30{
  margin-top: 30px;
}
.mb-0{
margin-bottom: 0px;
}
.t-center{
text-align: center;
}
.z-4{
    z-index: 4;
}

.relative{
    position: relative;
}
.copy-position{
  position: absolute;
  right: 5%
}
.authenticator{
  color: var(--primary-yellow, #E5B910);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.t-center{
  text-align: center;
}