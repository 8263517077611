.dropdown{
    right: 8%;
    position: absolute;
    background-color: #FEFEFE;
    top: 50%;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    padding: 8px !important;
}
.dropdown-item{
    font-size: 14px;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;

}
.dropdown-item:hover{
    background-color: rgba(0, 0, 0, 0.12);
}